import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Modal, IconButton, Input, Button, Spinner } from "../Atomics";
import { cilX } from "@coreui/icons";
import HandleUIError from "../../utils/HandleUIError";
import Dropdown from 'react-bootstrap/Dropdown';
// @ts-ignore
//services
import { CFormCheck } from "@coreui/react";
import {
  createNewAlertType,
  getAlertCategory,
  getAlerts,
  notificationSounds,
  updateAlertType,
} from "../../services/alertServices";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import s3 from "../../utils/aws-config";
import toast from "react-hot-toast";
import { createActivityReq } from "../../services/activityLogService";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshAlerts: () => Promise<void>;
  alertId: string;
  alertName: string;
  alertColor: string;
  alertCategory: any;
  alertSound: any;
  alertIcon: any;
  openedFrom: number; // 1 likes edit, 2 likes create
  alerts: any;
}

// @ts-ignore
const NewAlertType: React.FC<Props> = ({
  visible,
  closeModal,
  refreshAlerts,
  openedFrom,
  alertId,
  alertName,
  alertColor,
  alertCategory,
  alertSound,
  alertIcon,
  alerts,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(alertName || "");
  const [color, setColor] = useState(alertColor || "");
  const [icon, setIcon] = useState(null);
  const [isDefault, setIsDefault] = React.useState(false);
  const [isHomeAler, setIsHomeAler] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [notificationSound, setNotificationSound] = useState([]);
  const [notificationSoundList, setNotificationSoundList] = useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedSound, setSelectedSound] = React.useState([]);
  const [errorImage, setErrorImage] = React.useState(false);
  const [soundFile, setSoundFile] = React.useState('');
  const maxWidth = 200;
  const maxHeight = 200;
  const maxSizeInBytes = 1024 * 1024; // 1MB
  const [selectedImage, setSelectedImage] = useState(null);

  const onSelect = (selectedList: any, selectedItem: any) => {
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    setSelectedOptions(selectedList);
  };

  const onSelectSound = (selectedList: any, selectedItem: any) => {


    let currentSound = notificationSoundList.filter((item: any) => item?.id == selectedItem.id);
    // @ts-ignore
    setSoundFile(currentSound[0]?.file_url)
    setSelectedSound(selectedList);
  };

  const onRemoveSound = (selectedList: any, removedItem: any) => {
    setSoundFile('')
    setSelectedSound(selectedList);
  };

  function onChangeIsDefault() {
    setIsDefault(!isDefault);
  }

  function onChangeIsDefaultHomeAlert() {
    setIsHomeAler(!isHomeAler);
  }

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  const handleFileUpload = async (file: any) => {
    if (typeof file == "string") {
      return file;
    }
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  const handleFileChange = (event: any) => {
    const files = event.target.files;

    if (files) {
      // Filter images with dimensions less than 200x200 and size less than 1MB
      const filteredImages: any = Array.from(files).filter((file: any) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        return (
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          file.size <= maxSizeInBytes
        );
      });

      if (filteredImages.length < 1) {
        setErrorImage(true);
      } else {
        setErrorImage(false);
      }

      setIcon(filteredImages[0]);
    }
  };

  async function onPressSend() {
    const isMoreThanThree = alerts?.filter((item: any) => item?.isHomeAlert)?.length - 1
    console.log('alert', isHomeAler)
    if (isHomeAler) {
      if (isMoreThanThree < 2) {
        if (name !== "") {
          setLoading(true);
          try {
            // @ts-ignore
            const category = // @ts-ignore
              selectedOptions?.length > 0 ? selectedOptions[0]?.id : "";
            // @ts-ignore
            const notification_sound_id = // @ts-ignore
              selectedSound?.length > 0 ? selectedSound[0]?.id : "";
            const iconImage = icon ? await handleFileUpload(icon) : alertIcon;

            if (openedFrom === 2) {
              await updateAlertType(name, color, iconImage, category, alertId, notification_sound_id);
              await createActivityReq('Alert Setting Updated Successfully')
            } else {
              await createNewAlertType(name, color, iconImage, category, isDefault, isHomeAler, notification_sound_id);
              await createActivityReq('Alert Setting Added Successfully')
            }
            toast.success('Alert settings updated successfully');
            refreshAlerts();
            setLoading(false);
            closeModal();
            setName("");
          } catch (e) {
            HandleUIError(e);
            closeModal();
            setName("");
            setLoading(false);
          }
        }
      } else {
        toast.error('Please Checked Less Than 4 Home Alerts');
      }
    } else {
      if (name !== "") {
        setLoading(true);
        try {
          // @ts-ignore
          const category = // @ts-ignore
            selectedOptions?.length > 0 ? selectedOptions[0]?.id : "";
          // @ts-ignore
          const notification_sound_id = // @ts-ignore
            selectedSound?.length > 0 ? selectedSound[0]?.id : "";


          const iconImage = icon ? await handleFileUpload(icon) : alertIcon;

          if (openedFrom === 2) {
            await updateAlertType(name, color, iconImage, category, alertId, notification_sound_id);
          } else {
            await createNewAlertType(name, color, iconImage, category, isDefault, isHomeAler, notification_sound_id);
          }
          toast.success('Alert settings updated successfully');
          refreshAlerts();
          setLoading(false);
          closeModal();
          setName("");
        } catch (e) {
          HandleUIError(e);
          closeModal();
          setName("");
          setLoading(false);
        }
      }
    }
  }

  useEffect(() => {
    fetchAlerts();
    fetchNotificationSound();
  }, []);

  async function fetchAlerts() {
    try {
      const response = await getAlertCategory();
      const options = response?.data?.map((val) => {
        return {
          id: val?.id,
          value: val?.name,
        };
      });
      console.log("options", options);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // @ts-ignore
      const newOption: any =
        openedFrom === 2
          ? options?.length > 0 &&
          options?.filter((data: any) => data.id == alertCategory?.id)
          : [];
      setSelectedOptions(newOption);

      // @ts-ignore
      setOptions(options);
    } catch (e) {
      HandleUIError(e);
    }
  }

  async function fetchNotificationSound() {
    try {
      const response = await notificationSounds();
      // @ts-ignore
      setNotificationSoundList(response?.data)

      const options = response?.data?.map((val) => {
        return {
          id: val?.id,
          value: val?.name,
        };
      });
      console.log("options", options);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // @ts-ignore
      const newOption: any =
        openedFrom === 2
          ? options?.length > 0 &&
          options?.filter((data: any) => data.id == alertSound)
          : [];

      console.log('newOption', alertSound)
      setSelectedSound(newOption);

      // @ts-ignore
      setNotificationSound(options);
    } catch (e) {
      HandleUIError(e);
    }
  }

  const handleSelectedImage = (item: any) => {
    setIcon(item); // State update karna jab user image select kare
  };

  const defaultImages = [
    "https://callguardian.s3.amazonaws.com/Icon_-_Bomb_Threat_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Earthquake_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Evacuation_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Fire_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Generic_Emergency_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Help_Needed_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Indoor_Hazmat_Incident_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Medical_Emergency_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Missing_Student_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Outdoor_Hazmat_Incident_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Precautionary_Lockdown_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Reunification_1700px-1.png",
    "https://callguardian.s3.amazonaws.com/Icon_-_Severe_Storm_1700px-2.png",
  ];

  return (
    <>
      <Modal visible={visible} onPressOut={closeModal}>
        <div className="new-staff-container new-alert-type-container">
          <div className="new-template-header d-flex justify-content-between align-items-center">
            <p className="text-light fs-5">
              {openedFrom === 2 ? "Edit Alert Type" : "New Alert Type"}
            </p>
            <IconButton icon={cilX} onClick={closeModal} />
          </div>
          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Name:</p>
            {openedFrom === 2 ? (
              <Input defaultValue={alertName} onChange={onChangeName} />
            ) : (
              <Input onChange={onChangeName} />
            )}
          </div>

          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Color: </p>
            {openedFrom === 2 ? (
              <input
                type="color"
                defaultValue={alertColor}
                onChange={(e) => setColor(e.target.value)}
              />
            ) : (
              <input
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            )}
          </div>

          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Icon:</p>
            {openedFrom === 2 ? (
              <input
                type="file"
                className="form-control w-50"
                onChange={handleFileChange}
              />
            ) : (
              <input
                type="file"
                className="form-control w-50"
                onChange={handleFileChange}
                accept="image/*"
              />
            )}
          </div>

          <div className="mt-3 row px-3">
            {/* {defaultImages.map((item => {
              return (
                <>
                  <div className="col-auto my-3 ">
                    <img src={item} width={"40px"} height={"40px"} alt="" />
                  </div>
                </>
              )
            }))} */}

            {defaultImages.map((item, index) => (
              <div
                key={index}
                className={`col-auto my-2 ${icon === item ? " border p-3 border-success bg-success rounded-circle d-flex align-items-center justify-content-center" : "rounded-circle d-flex align-items-center justify-content-center"}`}
                onClick={() => handleSelectedImage(item)} // Image click handle karna
                style={{ cursor: "pointer", position: "relative", width: "70px", height: "70px" }}
              >
                <img src={item} width={"50px"} height={"50px"} alt="" />
                {/* {selectedImage === item && (
                  <div
                    className="rounded-circle"
                    style={{
                      display: "none",
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "white",
                      borderRadius: "50%",
                      border: "2px solid green",
                    }}
                  ></div>
                )} */}
              </div>
            ))}
          </div>

          <div className="px-3">

            <>
              {icon ? (
                <div className="mt-3">
                  <h6>Selected Image</h6>
                  <img
                    src={(typeof icon == "string") ? icon : URL.createObjectURL(icon)}
                    alt={`Selected 1`}
                    width={30}
                    height={30}
                  />
                </div>
              ) : (
                <>
                  {(alertIcon && openedFrom === 2) && (
                    <div className="mt-3">
                      <h6>Icon</h6>
                      <img
                        src={alertIcon}
                        alt={`Selected 1`}
                        width={30}
                        height={30}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          </div>



          <div className="px-3">
            {errorImage && (
              <div>
                <p className="text-danger">
                  Images with dimensions less than 200x200 and size less than
                  1MB
                </p>
              </div>
            )}
          </div>

          {openedFrom !== 2 && (
            <div className="d-flex align-items-center ms-3 mt-3">
              <p className="fs-5 m-0 p-0 me-2">Set this alert as default?</p>
              <CFormCheck checked={isDefault} onChange={onChangeIsDefault} />
            </div>
          )}

          {true && (
            <>
              <div
                style={{
                  width: 300,
                }}
                className="ms-3 mt-3"
              >
                <p className="fs-5 mb-1 p-0 me-2">Category:</p>
                <Multiselect
                  singleSelect={true}
                  options={options}
                  selectedValues={selectedOptions}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="value"
                />
              </div>
            </>
          )}

          {true && (
            <>
              <div
                style={{
                  width: 300,
                }}
                className="ms-3 mt-3"
              >
                <p className="fs-5 mb-1 p-0 me-2">Notification Sound</p>
                <Multiselect
                  singleSelect={true}
                  options={notificationSound}
                  selectedValues={selectedSound}
                  onSelect={onSelectSound}
                  onRemove={onRemoveSound}
                  displayValue="value"
                />
              </div>
            </>
          )}

          {soundFile && (
            <>
              <div className="mt-3">

                <audio

                  controls
                  src={soundFile}
                  className="d-block ms-3"
                />
              </div>
            </>
          )}

          {/* 
          <div className="mt-3 ms-3 sound-dropdown">
            <p className="fs-5 mb-1 p-0 me-2">Category:</p>
            <Dropdown>
              <Dropdown.Toggle variant="transparent" className="border text-white w-50" id="dropdown-basic">
                Dropdown Button
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}

          {openedFrom !== 2 && (
            <div className="d-flex align-items-center ms-3 mt-3">
              <p className="fs-5 m-0 p-0 me-2">Is Home Alert</p>
              <CFormCheck checked={isHomeAler} onChange={onChangeIsDefaultHomeAlert} />
            </div>
          )}

          <div className="d-flex justify-content-end px-3 pb-3">
            <Button onClick={onPressSend} disabled={loading} className="px-4">
              {loading ? <Spinner /> : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewAlertType;
