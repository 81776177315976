import React from 'react';
import { IconButton, Modal } from "../Atomics";
import { cilX } from "@coreui/icons";

const ViewAlerts = ({ visible, closeModal, isView }) => {
    console.log('isView', isView)
    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">View Alert</p>
                        <IconButton
                            icon={cilX}
                            className="me-3"
                            onClick={closeModal}
                        />
                    </div>

                    <div className="d-flex p-2">
                        <p className="text-light fs-5 ms-3 pt-3">Type: </p>
                        <p className="text-color fs-5 ms-3 pt-3">{isView?.type ? isView?.type : isView?.alertType?.name}</p>
                    </div>

                    <div className="d-flex p-2">
                        <p className="text-light fs-5 ms-3 pt-3">Activated By: </p>
                        <p className="text-color fs-5 ms-3 pt-3">
                            {isView?.user?.firstName ? isView?.user?.firstName : isView?.sender?.firstName} {isView?.user?.lastName ? isView?.user?.lastName : isView?.sender?.lastName}
                        </p>
                    </div>

                    <div className="d-flex p-2">
                        <p className="text-light fs-5 ms-3 pt-3">Email: </p>
                        <p className="text-color fs-5 ms-3 pt-3">
                            {isView?.user?.email ? isView?.user?.email : isView?.sender?.email}
                        </p>
                    </div>

                    <div className="d-flex p-2 d-none">
                        <p className="text-light fs-5 ms-3 pt-3">Group: </p>
                        {/*<p className="text-color fs-5 ms-3 pt-3">{parentData?.group_name}</p>*/}
                    </div>

                    {isView?.message && (
                        <div className="p-2 py-3">
                            <p className="text-light fs-5 ms-3 pt-3">Message: </p>
                            <div className="ms-3">
                                {isView?.type === "Image" && (
                                    <>
                                        <img src={`${isView?.message}`} alt="imgs" width={150} height={150} />
                                    </>
                                )}
                                {(isView?.type === "voice" || isView?.type === "Voice") && (
                                    <>
                                        <audio controls>
                                            <source src={isView?.message} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </>
                                )}

                                {(isView?.type === "message" ||
                                    isView?.type === "mesage" ||
                                    isView?.type === "Message") && (
                                        <>
                                            <p className="text-color fs-5 ms-3 pt-3">{isView?.message}</p>
                                        </>
                                    )}
                            </div>
                        </div>
                    )}
                    {isView?.description && (
                        <div className="p-2 py-3">
                            <p className="text-light fs-5 ms-3 pt-3">Message: </p>
                            <div className="ms-3">
                                {isView?.type === "Image" && (
                                    <>
                                        <img src={`${isView?.description}`} alt="imgs" width={150} height={150} />
                                    </>
                                )}
                                {(isView?.type === "voice" || isView?.type === "Voice") && (
                                    <>
                                        <audio controls>
                                            <source src={isView?.message} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </>
                                )}

                                {(isView?.type === "message" ||
                                    isView?.type === "mesage" ||
                                    isView?.type === "Message") && (
                                        <>
                                            <p className="text-color fs-5 ms-3 pt-3">{isView?.description}</p>
                                        </>
                                    )}
                            </div>
                        </div>
                    )}

                </div>
            </Modal>
        </>
    );
};

export default ViewAlerts;